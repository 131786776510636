(function() {
    "use strict";
    $(document).ready(function () {
        $('.datepicker').datepicker({
            format: "dd.mm.yyyy",
            weekStart: 1,
            clearBtn: true,
            autoclose: true,
            todayHighlight: true
        });
    });

    $('a[data-confirm], button[data-confirm], input[data-confirm]').on('click', function (e) {
        if (!confirm($(this).attr('data-confirm'))) {
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    });

    $('a[data-method]').on('click',function(e){

        var $form = $('<form/>').hide();

        //form options
        $form.attr({
            'action' : $(this).attr('href'),
            'method':'post'
        });

        //adding the _method hidden field
        $form.append($('<input/>',{
            type:'hidden',
            name:'_method'
        }).val($(this).data('method')));

        //adding a CSRF if needs
        if ($(this).data('csrf'))
        {
            var csrf = $(this).data('csrf').split(':');
            $form.append($('<input/>',{
                type:'hidden',
                name:csrf[0]
            }).val(csrf[1]));
        }

        //add form to parent node
        $(this).parent().append($form);

        $form.submit();

        e.preventDefault();
        return false;
    });
}).call(this);